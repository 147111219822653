import { useState } from "react";
import { Skeleton } from "./ui/skeleton";

interface ImageProps {
    src: string;
    alt: string;
    className: string;
    priority: boolean;
}

export default function ImageWithLoading({ src, alt, className, priority }: ImageProps) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    return (
        <div className="relative w-[400px] h-[400px]">
            {loading && !error && <Skeleton className="absolute inset-0 w-full h-full" />}
            {!error ? (
                <img
                    src={src}
                    alt={alt}
                    className={`${className} transition-opacity duration-300 ${loading ? 'opacity-0' : 'opacity-100'}`}
                    width={400}
                    height={400}
                    onLoad={() => setLoading(false)}
                    onError={() => {
                        setLoading(false);
                        setError(true);
                    }}
                    loading={priority ? "eager" : "lazy"}
                />
            ) : (
                <p className="text-red-500 text-center">Erro ao carregar a imagem</p>
            )}
        </div>
    );
}
