import { useEffect, useState } from "react";
import { FilterModal } from "./components/FilterModal";
import { RoomCarousel } from "./components/RoomCarousel";
import { Room } from "@/types/room";
import api from "@/hooks/useApi";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Helmet } from "react-helmet";
import { slugify } from "@/utils/slugify";
import { getLocationName } from "@/utils/getLocationName";

export interface Filter {
    property_uuid?: string;
    max_price?: string;
    status?: string;
}

export default function RoomsPage() {
    const [totalPages, setTotalPages] = useState<number>(1);
    const [rooms, setRooms] = useState<Room[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<Filter>({
        property_uuid: "",
        status: "",
        max_price: ""
    });

    const current_page: number = Number(searchParams.get('pagination')) || 1;
    const navigate = useNavigate();
    const location = useLocation();
    let locationName = "";

    useEffect(() => {
        if (!searchParams.get("pagination")) {
            setSearchParams({ pagination: "1" });
        }

        locationName = getLocationName();

        if (locationName !== 'lisboa') {
            const fetchProperties = async () => {
                await api.get(`/website/properties?pagination=${current_page}`)
                    .then(success => {
                        const property = success.data.properties.filter((property: { name: string }) =>
                            slugify(property.name) == locationName
                        )[0];

                        console.log(property)
                        setFilter((prev) => ({ ...prev, property_uuid: property.uuid }));
                    })
                    .catch(() => {
    
                    })
                    .finally(() => {

                    })
            }

            fetchProperties()

            return
        }
    }, [location.pathname, searchParams]);

    useEffect(() => {
        if (!filter.property_uuid && locationName !== "lisboa") return;
        
        let parameter = "";
        if (filter.property_uuid) parameter += `&property_uuid=${filter.property_uuid}`;
        if (filter.max_price) parameter += `&max_price=${filter.max_price}`;
        if (filter.status) parameter += `&status=${filter.status}`;
        
        const fetchRooms = async () => {
            await api.get(`/website/rooms?pagination=${current_page}${parameter}`)
                .then(success => {
                    setRooms(success.data.rooms);
                    setTotalPages(success.data.total_pages);
                })
                .catch((error) => {
                    console.error("Error fetching rooms:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        fetchRooms();
    }, [current_page, filter]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    const handlePaginationChange = (new_page: number) => {
        setLoading(true);
        navigate(`?pagination=${new_page}`);
    };

    if (loading) {
        return (
            <div className="w-full min-h-screen flex items-center justify-center">
                <Loader2 className="animate-spin" />
            </div>
        );
    }

    const property_name = getLocationName() == 'famoes' ? 'Famões' : getLocationName().charAt(0).toUpperCase() + getLocationName().slice(1);

    const title = `Quartos para alugar em ${property_name}`;

    const description = `Encontre os melhores quartos para alugar em ${property_name}. Oferecemos opções de quartos confortáveis e bem localizados. Alugue o seu quarto em ${property_name} de forma rápida, segura e acessível.`;

    const keywords = `quarto para alugar em ${property_name}, quarto para arrendar em ${property_name}, quarto em ${property_name}`;

    const canonical_url = `https://www.alugarumquarto.pt/em-${getLocationName()}/quartos-para-alugar-em-${getLocationName()}`;

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords}/>

                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical_url} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />

                <meta name="robots" content="index, follow" />

                <link rel="canonical" href={canonical_url} />
                
                <link rel="preload" href={rooms[0]?.media_content.images[0]} as="image" fetchPriority="high"/>
            </Helmet>
            <div className="py-10 px-4 flex flex-col items-center gap-5">
                <div className="flex flex-col items-center w-full max-w-[1600px] gap-5">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center">
                        Quartos para alugar em {property_name} - Encontre o seu lugar ideal
                    </h1>
                    <h2 className="text-center text-xl md:text-2xl lg:text-3xl">
                        Descubra as melhores opções de quartos para alugar em {property_name} e escolha o que mais combina com você.
                    </h2>
                    <h3 className="text-center text-base md:text-xl lg:text-2xl">
                        Quartos para alugar em {property_name} com conforto e praticidade, disponíveis para você hoje mesmo!
                    </h3>
                    <p className="text-center text-sm md:text-base lg:text-xl">
                        Veja todas as ofertas de quartos para alugar em {property_name} abaixo e encontre sua nova acomodação agora.
                    </p>
                    <div className="flex justify-start w-full">
                        <FilterModal filter={filter} setFilter={setFilter} current_page={current_page} setTotalPages={setTotalPages} setRooms={setRooms} />
                    </div>
                    {rooms.length === 0 ? (
                        <div className="p-4">
                            <p className="text-3xl font-semibold text-center">Nenhum quarto foi encontrado.</p>
                        </div>
                    ) : (
                        <div className="w-full flex flex-col items-center sm:items-start sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                            {rooms.map((room, index) => (
                                <div key={index} className="flex flex-col w-full sm:mt-3 sm:max-w-[320px] lg:max-w-[350px]">
                                    <RoomCarousel room={room} room_index={index}/>
                                    <div className="pt-2">
                                        <h3 className="text-lg lg:text-xl font-bold">{room.name}</h3>
                                        <p className="lg:text-lg">{room.address}</p>
                                        <p className="font-semibold lg:text-lg">€{room.price.toString().replace(".", ",")} <span className="font-normal"> por mês</span></p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {totalPages > 1 && (
                    <div className="flex justify-end items-center gap-3">
                        <Button
                            aria-label="Ir para página anterior"
                            variant={"outline"}
                            onClick={() => handlePaginationChange(current_page - 1)}
                            disabled={current_page === 1}>
                            <ChevronLeft />
                        </Button>
                        <p className="text-primary/70">{current_page}/{totalPages}</p>
                        <Button
                            aria-label="Ir para a próxima página"
                            variant={"outline"}
                            onClick={() => handlePaginationChange(current_page + 1)}
                            disabled={totalPages === current_page}>
                            <ChevronRight />
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}
