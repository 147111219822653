import { Link, Outlet, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";

import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetTrigger,
} from "@/components/ui/sheet"
import { useEffect, useState } from "react";
import useMediaQuery from "@/hooks/useMediaQuery";
import { Button } from "@/components/ui/button";
import Footer from "./Footer";

export default function Navbar() {
    const [open, setOpen] = useState(false)
    const is_desktop = useMediaQuery()
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <header className="sticky top-0 left-0 px-5 h-[4rem] items-center lg:px-5 py-10 z-50 w-full flex lg:gap-2 justify-between border-b border-border/10 bg-[#2076bd] backdrop-blur supports-[backdrop-filter]:bg-[#2076bd]">
                <div className="flex flex-row-reverse justify-between w-full lg:w-auto items-center gap-2">
                    {!is_desktop &&

                        <Sheet open={open} onOpenChange={setOpen}>
                            <SheetTrigger asChild>
                                <Button aria-label="Abrir menu" className="text-white inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 h-9 py-2 mr-2 px-0 text-base focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 lg:hidden">
                                    <Menu />
                                </Button>
                            </SheetTrigger>
                            <SheetContent side={"right"} className="bg-[#2076bd]">
                                <div className="w-full flex justify-end">
                                    <SheetClose className="flex justify-end">
                                        <X className="text-white" />
                                    </SheetClose>
                                </div>
                                <ul className="mt-5 text-xl font-semibold flex flex-col gap-4 text-white text-center">
                                    <li className="hover:text-white/70">
                                        <a href="/em-lisboa/quartos-para-alugar-em-lisboa">
                                            Quartos para alugar em Lisboa
                                        </a>
                                    </li>
                                    <li className="hover:text-white/70">
                                        <Link to={"/propriedades"}>
                                            Propriedades
                                        </Link>
                                    </li>
                                    <li className="hover:text-white/70">
                                        <Link to={"/quem-somos"}>
                                            Quem somos
                                        </Link>
                                    </li>
                                    {/* <li className="hover:text-white/70">
                                        <Link to={"/descubra-o-seu-inquilino-ideal"}>
                                            Descubra o seu inquilino ideal
                                        </Link>
                                    </li> */}
                                    <li className="hover:text-white/70">
                                        <Link to={"/regras"}>
                                            Regras
                                        </Link>
                                    </li>
                                </ul>
                            </SheetContent>
                        </Sheet>

                    }
                    <Link to="/"><img loading="lazy" src="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" alt="Logo da Alugar Um Quarto" className="w-12 h-12 lg:w-14 lg:h-14" /></Link>
                </div>
                <div className="flex items-center gap-5 h-full">
                    {is_desktop &&
                        <ul className="flex items-center gap-7 text-base lg:text-xl text-white">
                            <li className="hover:text-white/70">
                                <a href="/em-lisboa/quartos-para-alugar-em-lisboa">
                                    Quartos para alugar em Lisboa
                                </a>
                            </li>
                            <li className="hover:text-white/70">
                                <Link to={"/propriedades"}>
                                    Propriedades
                                </Link>
                            </li>
                            <li className="hover:text-white/70">
                                <Link to={"/quem-somos"}>
                                    Quem somos
                                </Link>
                            </li>
                            {/* <li className="hover:text-white/70">
                                <Link to={"/descubra-o-seu-inquilino-ideal"}>
                                    Descubra o seu inquilino ideal
                                </Link>
                            </li> */}
                            <li className="hover:text-white/70">
                                <Link to={"/regras"}>
                                    Regras
                                </Link>
                            </li>

                        </ul>
                    }

                </div>
            </header>
            <Outlet />
            <Footer />
        </>


    )
}
