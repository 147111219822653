import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { z } from "zod";
import { Loader2, X } from "lucide-react";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import api from "@/hooks/useApi";
import Cookies from "universal-cookie";

const form_schema = z.object({
    name: z.string(),
    email: z.string().email(),
    phone_number: z.string(),
})

interface Props {
    room_uuid: string;
    room_name: string;
    property_name: string;
}

export default function LeadModal({ room_uuid, room_name, property_name }: Props) {
    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const cookies = new Cookies(null, { path: "/" })

    const form = useForm<z.infer<typeof form_schema>>({
        resolver: zodResolver(form_schema),
        defaultValues: {
            name: "",
            email: "",
            phone_number: ""
        }
    })


    const handleSubmit = async (data: z.infer<typeof form_schema>) => {
        setLoading(true)

        await api.post("/leads/store", { ...data, type: "tenant", room_uuid })
            .then((success) => {
                cookies.set("lead-cookie", success.data.lead.uuid)

                window.location.href = `https://wa.me/351961639797?text=${encodeURIComponent(`Olá, meu nome é ${data.name} e eu estou interessado no "${room_name}" da propriedade de ${property_name}. Eu encontrei esse anúncio pelo site da Alugar Um Quarto.`)}`;

            })
            .catch(() => {

            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleClose = () => {
        form.reset()
        setOpen(!open)
    }

    return (
        <AlertDialog open={open} onOpenChange={handleClose}>
            <AlertDialogTrigger asChild>
                <Button id="contact-us-room-page-button" className="font-semibold w-full">
                    Entrar em contato conosco
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <Button disabled={loading} onClick={handleClose} className="bg-transparent hover:bg-transparent text-black absolute right-0 top-1 opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                    <X />
                </Button>

                <AlertDialogHeader className="mt-5">
                    <AlertDialogTitle className="text-2xl md:text-3xl lg:text-4xl text-[#0D509E]">
                        Cadastre-se abaixo para falar conosco agora!
                    </AlertDialogTitle>
                </AlertDialogHeader>

                <Form {...form}>
                    <form
                        id="lead-form"
                        onSubmit={form.handleSubmit(handleSubmit)}
                        className="space-y-4 w-full bg-white rounded-[calc(0.75rem-2px)]"
                    >

                        <FormField
                            name="name"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="font-extralight">Nome completo *</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] bg-opacity-60 py-6"
                                            placeholder="Digite seu nome completo"
                                            disabled={loading}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="email"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="font-extralight">Email *</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="email"
                                            className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] bg-opacity-60 py-6"
                                            placeholder="Digite seu email"
                                            disabled={loading}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="phone_number"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="font-extralight">Número de telefone *</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="tel"
                                            className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] bg-opacity-60 py-6"
                                            placeholder="Digite seu número de telefone"
                                            disabled={loading}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <div className="flex justify-center py-4">
                            <Button
                                disabled={loading}
                                form="lead-form"
                                type="submit"
                                className="w-full text-lg py-6 px-8 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] hover:filter hover:brightness-90 transition duration-300">
                                {loading ? <Loader2 className="animate-spin" /> : "Falar conosco"}
                            </Button>
                        </div>
                    </form>
                </Form>

            </AlertDialogContent>
        </AlertDialog>
    )
}