import { Button } from "@/components/ui/button";
import ImagesSection from "./components/ImagesSection";
import VideosSection from "./components/VideosSection"; // Adicionei uma nova seção para vídeos
import { ArrowLeft, Video, Image, Loader2 } from "lucide-react"; // Adicionei o ícone de imagem
import { Room } from "@/types/room";
import { useEffect, useState } from "react";
import { Separator } from "@/components/ui/separator";
import MapComponent from "./components/MapComponent";
import { Link, useSearchParams } from "react-router-dom";
import api from "@/hooks/useApi";
import { Helmet } from "react-helmet";
import LeadModal from "./components/LeadModal";
import Cookies from "universal-cookie";
import { getLocationName } from "@/utils/getLocationName";

export default function RoomsShowPage() {
    const cookies = new Cookies(null, { path: "/" })
    const [loading, setLoading] = useState<boolean>(true)
    const [room, setRoom] = useState<Room>();
    const [searchParams, setSearchParams] = useSearchParams({ view: "images" })
    const view_params = searchParams.get("view")

    useEffect(() => {
        const fetchRoom = async () => {
            await api.get(`/website/rooms/show?room_uuid=${searchParams.get("room_uuid")}`)
                .then(success => {
                    setRoom(success.data.room)
                })
                .catch(() => {

                })
                .finally(() => {
                    setLoading(false)
                })
        }

        fetchRoom()
    }, [])

    if (loading) {
        return (
            <div className="w-full min-h-screen flex items-center justify-center">
                <Loader2 className="animate-spin" />
            </div>
        )
    }

    if (!room) {
        return (
            <div className="p-4">
                <p className="text-3xl font-semibold text-center">Ocorreu um erro ao tentar carregar as informações desse quarto.</p>
            </div>
        )
    }
    
    const property_name = getLocationName() == 'famoes' ? 'Famões' : getLocationName().charAt(0).toUpperCase() + getLocationName().slice(1);

    return (
        <>
            <Helmet>
                <title>Alugar Um Quarto | {room.property_name} | {room.name}</title>
                <meta name="description" content={`Conheça agora um dos quartos da Alugar Um Quarto! ${room.name} | Propriedade de ${room.property_name}`} />

                <meta property="og:title" content={`Alugar Um Quarto em ${property_name}`} />
                <meta property="og:description" content={`Alugar um quarto em ${property_name} nunca foi tão fácil! Oferecemos quartos confortáveis e bem localizados. Encontre e alugue o seu quarto ideal em ${property_name} hoje mesmo.`} />
                <meta property="og:url" content="https://www.alugarumquarto.pt/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />
            </Helmet>

            <div className="w-full flex flex-col items-center">
                <div className="w-full max-w-6xl flex flex-col items-center">
                    <div className="py-3 flex flex-col lg:flex-row gap-2 justify-between w-full px-2">
                        <Link to="/em-lisboa/quartos-para-alugar-em-lisboa">
                            <Button variant={"link"} className="p-0 text-lg">
                                <ArrowLeft />
                                Voltar para a lista de quartos em Lisboa
                            </Button>
                        </Link>
                        {view_params === "images" ?
                            room.media_content?.videos?.length > 0 && (
                                <Button
                                    className="flex gap-1"
                                    onClick={() => setSearchParams({ view: "videos" })}
                                >
                                    <Video />
                                    Ver vídeos do quarto
                                </Button>
                            )

                            :

                            room.media_content?.images?.length > 0 && (
                                <Button className="flex gap-1" onClick={() => setSearchParams({ view: "images" })}>
                                    <Image />
                                    Ver imagens do quarto
                                </Button>
                            )
                        }
                    </div>

                    {view_params === "videos" ? <VideosSection videos={room.media_content.videos} /> : <ImagesSection images={room.media_content.images} />}

                    <div className="px-4 text-left w-full py-3">
                        <div className="flex w-full items-center justify-between">

                            <p className="text-2xl font-bold">{room.name}</p>
                            <p className="font-semibold text-lg">
                                €{room.price.toString().replace(".", ",")} por mês
                            </p>
                        </div>
                        <div>
                            <Separator className="my-3" />
                            <div>
                                <p className="text-xl font-semibold">Endereço</p>
                                <p className="text-lg">
                                    {room.address}
                                </p>
                            </div>
                            <Separator className="my-3" />
                            <div className="flex flex-col">
                                <p className="text-xl font-semibold">O que esse lugar oferece</p>
                                <div className="flex flex-wrap gap-1">
                                    {room.items_list.map((item, index) => (
                                        <p key={index}>
                                            {item.quantity} {item.item}{" "}
                                            {index !== room.items_list.length - 1 && <span> · </span>}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <Separator className="my-3" />
                            <div className="flex flex-col items-start">
                                <div className="flex justify-between items-center w-full mb-3">
                                    <p className="text-xl font-semibold">Disponibilidade</p>
                                    <div className={`text-sm text-white rounded-2xl px-4 py-2 flex items-center font-semibold ${room.status === "busy" ? "bg-red-500" : "bg-green-500"}`}>
                                        {room.status === "busy" ?
                                            "Ocupado"
                                            :
                                            "Disponível"
                                        }
                                    </div>
                                </div>
                                {/* {room.status === "busy" ?
                                    <Button className="font-semibold w-full">Entrar na lista de espera</Button>
                                    :
                                    <Button className="font-semibold w-full">Entrar em contato conosco</Button>
                                } */}
                                {cookies.get("lead-cookie") ?
                                    <Link id="contact-us-room-page-button" to={`https://wa.me/351961639797?text=${encodeURIComponent(`Olá, eu estou interessado no "${room.name}" da propriedade de ${room.property_name}. Eu encontrei esse anúncio pelo site da Alugar Um Quarto.`)}`} target="_blank" className="w-full">
                                        <Button className="font-semibold w-full">
                                            Entrar em contato conosco
                                        </Button>
                                    </Link>
                                    :
                                    <LeadModal room_uuid={room.uuid} room_name={room.name} property_name={room.property_name} />
                                }
                                {/* <p className="text-sm">
                                    O quarto ficará disponível em:{" "}
                                    {dayjs(room.available_at).format("DD/MM/YYYY")}
                                </p> */}
                            </div>
                            <Separator className="my-3" />
                            <div>
                                <p className="text-xl font-semibold">Veja a localização do quarto no mapa</p>
                                <div className="mt-2">
                                    <MapComponent address={room.address} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}