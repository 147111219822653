import { BrowserRouter, Routes, Route } from "react-router-dom"
import WelcomePage from "./pages/welcome-page"
import Navbar from "./layouts/Navbar"
import RoomsPage from "./pages/rooms"
import RoomsShowPage from "./pages/rooms-show"
import PropertiesPage from "./pages/properties"
import PropertiesShowPage from "./pages/properties-show"
import AboutUsPage from "./pages/about-us"
// import RealEstatePage from "./pages/real-estate"
import MapContainer from "./layouts/MapContainer"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RulesPage from "./pages/rules"

function App() {
  return (


    <BrowserRouter>
      <Routes>
        <Route element={<Navbar />}>

          <Route path="/" element={<WelcomePage />} />
          <Route path="/propriedades" element={<PropertiesPage />} />
          
          <Route path="/em-lisboa/quartos-para-alugar-em-lisboa" element={<RoomsPage />} />
          
          <Route path="/em-pontinha/quartos-para-alugar-em-pontinha" element={<RoomsPage />} />
          <Route path="/em-corroios/quartos-para-alugar-em-corroios" element={<RoomsPage />} />
          <Route path="/em-reboleira/quartos-para-alugar-em-reboleira" element={<RoomsPage />} />
          <Route path="/em-famoes/quartos-para-alugar-em-famoes" element={<RoomsPage />} />
          <Route path="/em-corroios-cave/quartos-para-alugar-em-corroios-cave" element={<RoomsPage />} />
          
          <Route element={<MapContainer />}>
            <Route path="/em-pontinha" element={<PropertiesShowPage />} />
            <Route path="/em-corroios" element={<PropertiesShowPage />} />
            <Route path="/em-reboleira" element={<PropertiesShowPage />} />
            <Route path="/em-famoes" element={<PropertiesShowPage />} />
            <Route path="/em-corroios-cave" element={<PropertiesShowPage />} />

            <Route path="/em-pontinha/quartos-para-alugar-em-pontinha/:room_name" element={<RoomsShowPage />} />
            <Route path="/em-corroios/quartos-para-alugar-em-corroios/:room_name" element={<RoomsShowPage />} />
            <Route path="/em-reboleira/quartos-para-alugar-em-reboleira/:room_name" element={<RoomsShowPage />} />
            <Route path="/em-corroios-cave/quartos-para-alugar-em-corroios-cave/:room_name" element={<RoomsShowPage />} />
          </Route>

          <Route path="/quem-somos" element={<AboutUsPage />} />

          <Route path="/regras" element={<RulesPage />} />
          {/* <Route path="/descubra-o-seu-inquilino-ideal" element={<RealEstatePage />} /> */}
        </Route>
      </Routes>
      <ToastContainer/>
    </BrowserRouter>
  )
}

export default App
