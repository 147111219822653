import ImageWithLoading from "@/components/ImageWithLoading"
import { Card, CardContent } from "@/components/ui/card"
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import { Room } from "@/types/room"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { slugify } from "@/utils/slugify";
import { getLocationName } from "@/utils/getLocationName"

export function RoomCarousel({ room, room_index }: { room: Room, room_index: number }) {

  const navigate = useNavigate()

  const [api, setApi] = useState<CarouselApi>()
  const [current, setCurrent] = useState<number>(0)
  const [_, setCount] = useState<number>(0) 
  let locationName = getLocationName()

  if (locationName == 'lisboa') {
    locationName = slugify(room.property_name);
  }
  useEffect(() => {
    if (!api) return
    
    setCount(room.media_content.images.length) 
    setCurrent(api.selectedScrollSnap()) 

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap()) 
    })
  }, [api, room.media_content.images.length])

  return (
    <div className="relative w-full max-w-full">
      <Carousel setApi={setApi}>
        <CarouselContent>
          {room.media_content.images.slice(0, current + 2).map((image, index) => (
            <CarouselItem key={index}>
              <Card>
                <CardContent
                  onClick={() =>
                    navigate(`/em-${locationName}/quartos-para-alugar-em-${locationName}/${slugify(room.name)}?room_uuid=${room.uuid}`)
                  }
                  className="cursor-pointer flex aspect-square p-0 items-center justify-center"
                >
                  <ImageWithLoading src={image} alt="Imagem do quarto" className="show-room-button w-full h-full rounded-lg object-cover" priority={room_index === 0 && index === 0}/>
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <div
        className={`absolute right-2 top-2 text-sm text-white rounded-2xl px-4 py-2 flex items-center font-semibold ${
          room.status === "busy" ? "bg-red-500" : "bg-green-500"
        }`}
      >
        {room.status === "busy" ? "Ocupado" : "Disponível"}
      </div>
    </div>
  )
}
