import { useEffect, useState } from "react";
import { PropertyCarousel } from "./components/PropertyCarousel";
import api from "@/hooks/useApi";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Property } from "@/types/property";
import { Helmet } from "react-helmet";

export default function PropertiesPage() {
    const [totalPages, setTotalPages] = useState<number>(1)
    const [properties, setProperties] = useState<Property[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [searchParams] = useSearchParams()
    const current_page: number = Number(searchParams.get('pagination')) || 1
    const navigate = useNavigate()

    useEffect(() => {
        const fetchProperties = async () => {
            await api.get(`/website/properties?pagination=${current_page}`)
                .then(success => {
                    setProperties(success.data.properties)
                    setTotalPages(success.data.total_pages)
                })
                .catch(() => {

                })
                .finally(() => {
                    setLoading(false)
                })
        }


        fetchProperties()
    }, [current_page])

    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    const handlePaginationChange = (new_page: number) => {
        setLoading(true)
        navigate(`?pagination=${new_page}`)
    }

    if (loading) {
        return (
            <div className="w-full h-[90vh] self-center place-self-center flex items-center justify-center">
                <Loader2 className="animate-spin" />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Propriedades em Lisboa</title>
                <meta name="description" content="Encontre as melhores propriedades para alugar um quarto em Lisboa. Oferecemos opções de quartos confortáveis e bem localizados. Alugue o seu quarto em Lisboa de forma rápida, segura e acessível." />

                <meta property="og:title" content="Alugar Um Quarto em Lisboa" />
                <meta property="og:description" content="Alugar um quarto em Lisboa nunca foi tão fácil! Oferecemos quartos confortáveis e bem localizados. Encontre e alugue o seu quarto ideal em Lisboa hoje mesmo." />
                <meta property="og:url" content="https://www.alugarumquarto.pt/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />
            </Helmet>
            <div className="py-10 px-4 flex flex-col items-center gap-5">
                <div className="flex flex-col items-center w-full max-w-[1600px] gap-5">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center">Propriedades em Lisboa</h1>
                    <h2 className="text-center text-xl md:text-2xl lg:text-3xl">
                        Veja abaixo todas as propriedades em Lisboa disponíveis!
                    </h2>
                    {properties.length === 0 ?
                        <div className="p-4">
                            <p className="text-3xl font-semibold text-center">Nenhuma propriedade foi encontrado.</p>
                        </div>
                        :
                        <div className="w-full flex flex-col items-center sm:items-start sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                            {properties.map((property, index) => (
                                <div key={index} className="flex flex-col w-full sm:mt-3 sm:max-w-[320px] lg:max-w-[350px]">
                                    <PropertyCarousel property={property} property_index={index}/>
                                    <div className="pt-2">
                                        <h3 className="font-bold text-lg lg:text-xl">{property.name}</h3>
                                        <p className="lg:text-lg">{property.address}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                {totalPages > 1 &&
                    <div className="flex justify-end items-center gap-3">
                        <Button
                            variant={"outline"}
                            onClick={() => handlePaginationChange(current_page - 1)}
                            disabled={current_page === 1}>
                            <ChevronLeft />
                        </Button>
                        <p className="text-primary/70">
                            {current_page}/{totalPages}
                        </p>
                        <Button
                            variant={"outline"}
                            onClick={() => handlePaginationChange(current_page + 1)}
                            disabled={totalPages === current_page}>
                            <ChevronRight />
                        </Button>
                    </div>
                }
            </div>
        </>

    )
}