import { Button } from "@/components/ui/button";
import ImagesSection from "./components/ImagesSection";
import VideosSection from "./components/VideosSection"; // Adicionei uma nova seção para vídeos
import { ArrowLeft, Video, Image, Loader2 } from "lucide-react"; // Adicionei o ícone de imagem
import { useEffect, useState } from "react";
import { Separator } from "@/components/ui/separator";
import MapComponent from "./components/MapComponent";
import { Link, useSearchParams } from "react-router-dom";
import api from "@/hooks/useApi";
import { Property } from "@/types/property";
import { Helmet } from "react-helmet";
import { slugify } from "@/utils/slugify";
import { getLocationName } from "@/utils/getLocationName";

export default function PropertiesShowPage() {
    const [loading, setLoading] = useState<boolean>(true)
    const [property, setProperty] = useState<Property>();
    const [searchParams, setSearchParams] = useSearchParams({ view: "images" })
    const view_params = searchParams.get("view")

    const fetchProperties = async () => {
        await api.get(`/website/properties?pagination=1`)
            .then(success => {
                const property = success.data.properties.filter((property: { name: string }) =>
                    slugify(property.name) == getLocationName()
                )[0];

                api.get(`/website/properties/show?property_uuid=${property.uuid}`)
                    .then(success => {
                        setProperty(success.data.property)
                    })
                    .catch(() => {
        
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {

            })
            .finally(() => {
                
            })
    }

    useEffect(() => {
        fetchProperties()
    }, [])

    if (loading) {
        return (
            <div className="w-full min-h-screen flex items-center justify-center">
                <Loader2 className="animate-spin" />
            </div>
        )
    }

    if (!property) {
        return (
            <div className="p-4">
                <p className="text-3xl font-semibold text-center">Ocorreu um erro ao tentar carregar as informações dessa propriedade.</p>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Alugar Um Quarto | Propriedade de {property.name}</title>
                <meta name="description" content={`Conheça agora uma das propriedades da Alugar Um Quarto! Propriedade de ${property.name}`} />

                <meta property="og:title" content="Alugar Um Quarto em Lisboa" />
                <meta property="og:description" content="Alugar um quarto em Lisboa nunca foi tão fácil! Oferecemos quartos confortáveis e bem localizados. Encontre e alugue o seu quarto ideal em Lisboa hoje mesmo." />
                <meta property="og:url" content="https://www.alugarumquarto.pt/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />

                <meta name="robots" content="index, follow" />

                <link rel="canonical" href={`https://www.alugarumquarto.pt/em-${slugify(property.name)}`} />
            </Helmet>
            <div className="w-full flex flex-col items-center">
                <div className="w-full max-w-6xl flex flex-col items-center">
                    <div className="py-3 flex flex-col lg:flex-row gap-2 justify-between w-full px-2">
                        <Link to="/propriedades">
                            <Button variant={"link"} className="p-0 text-lg">
                                <ArrowLeft />
                                Voltar para a lista de propriedades
                            </Button>
                        </Link>
                        {view_params === "images" ?
                            property.media_content?.videos?.length > 0 && (
                                <Button
                                    className="flex gap-1"
                                    onClick={() => setSearchParams({ view: "videos" })}
                                >
                                    <Video />
                                    Ver vídeos da propriedade
                                </Button>
                            )

                            :

                            property.media_content?.images?.length > 0 && (
                                <Button className="flex gap-1" onClick={() => setSearchParams({ view: "images" })}>
                                    <Image />
                                    Ver imagens da propriedade
                                </Button>
                            )


                        }
                    </div>

                    {view_params === "videos" ?
                        <VideosSection videos={property.media_content?.videos} />
                        :
                        <ImagesSection images={property.media_content?.images} />
                    }

                    <div className="px-4 text-left w-full py-3">
                        <div className="flex w-full items-center justify-between">

                            <p className="text-2xl font-bold">{property.name}</p>
                        </div>
                        <div>
                            <Separator className="my-3" />
                            <div>
                                <p className="text-xl font-semibold">Endereço</p>
                                <p className="text-lg">
                                    {property.address}
                                </p>
                            </div>
                            <Separator className="my-3" />
                            <div className="flex flex-col">
                                <p className="text-xl font-semibold">O que esse lugar oferece</p>
                                <div className="flex flex-wrap gap-1">
                                    {property.items_list.map((item, index) => (
                                        <p key={index}>
                                            {item.quantity} {item.item}{" "}
                                            {index !== property.items_list.length - 1 && <span> · </span>}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <Separator className="my-3" />
                            <div>
                                <p className="text-xl font-semibold">Veja a localização da propriedade no mapa</p>
                                <div className="mt-2">
                                    <MapComponent address={property.address} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}