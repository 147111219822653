import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import api from "@/hooks/useApi";
import { Property } from "@/types/property";
import { Room } from "@/types/room";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import z from "zod"
import { Filter } from "..";
import { useSearchParams } from "react-router-dom";
import { slugify } from "@/utils/slugify";

interface Props {
    properties: Property[];
    setProperties: (data: Property[]) => void;
    open: boolean;
    setOpen: (data: boolean) => void;
    setRooms: (data: Room[]) => void;
    current_page: number;
    filter: Filter;
    setFilter: (data: Filter) => void;
    setTotalPages: (data: number) => void;
}

export default function FilterForm({ setTotalPages, properties, setProperties, open, setOpen, setRooms, current_page, filter, setFilter }: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    const [propertiesLoading, setPropertiesLoading] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectOpen, setSelectOpen] = useState<boolean>(false)
    const form_schema = z.object({
        property_uuid: z.string().optional(),
        max_price: z.string().optional(),
        status: z.string().optional(),
    });
    const navigate = useNavigate()

    const form = useForm<z.infer<typeof form_schema>>({
        resolver: zodResolver(form_schema),
        defaultValues: filter
    });

    const fetchProperties = async () => {
        setPropertiesLoading(true)

        await api.get(`/website/properties?pagination=1`)
            .then(success => {
                setProperties(success.data.properties)
            })
            .catch(() => {
            })
            .finally(() => {
                setPropertiesLoading(false)
            })
    }

    useEffect(() => {
        fetchProperties()
    }, [])

    const handleSubmit = async (data: z.infer<typeof form_schema>) => {
        setLoading(true);

        let parameter = "";
        if (data.property_uuid) parameter += `&property_uuid=${data.property_uuid}`;
        if (data.max_price) parameter += `&max_price=${data.max_price}`;
        if (data.status) parameter += `&status=${data.status}`;

        await api.get(`/website/rooms?pagination=${current_page}${parameter}`)
            .then(success => {
                setFilter(data)
                if (searchParams.get("pagination") === "1") {
                    setRooms(success.data.rooms)
                    setTotalPages(success.data.total_pages)
                }
                setSearchParams({ pagination: "1" })

                const property = properties.filter((property: { uuid: string }) =>
                    property.uuid == data.property_uuid
                )[0];

                handleClose()
                navigate(`/em-${slugify(property.name)}/quartos-para-alugar-em-${slugify(property.name)}`)
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        setOpen(!open);
    };

    const handleReset = async () => {
        setLoading(true);

        let parameter = "";

        await api.get(`/website/rooms?pagination=${current_page}${parameter}`)
            .then(success => {
                setRooms(success.data.rooms);
                setTotalPages(success.data.total_pages)
                handleClose()
                setTimeout(() => setSearchParams({ pagination: "1" }), 1000)

                setFilter({
                    property_uuid: "",
                    max_price: "",
                    status: ""
                });
                form.setValue("status", "")
                form.setValue("property_uuid", "")
                form.setValue("max_price", "")

                handleClose()
                window.location.href = "/em-lisboa/quartos-para-alugar-em-lisboa?pagination=1"
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
                <FormField
                    control={form.control}
                    name="property_uuid"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Propriedade</FormLabel>
                            <Select
                                disabled={loading}
                                onValueChange={(value) => {
                                    form.setValue("property_uuid", value);
                                    if (!value) {
                                        form.setValue("property_uuid", "")
                                    }
                                }}
                                value={form.watch("property_uuid") === "" ? "" : field.value}
                                onOpenChange={() => {
                                    if (!selectOpen) {
                                        fetchProperties()
                                    }
                                    setSelectOpen(!selectOpen)
                                }}
                                open={selectOpen}
                            >
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Todas" />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {propertiesLoading ?
                                        <div className="flex justify-center w-full">
                                            <Loader2 className="animate-spin" />
                                        </div>
                                        :
                                        properties.map((property, index) => (
                                            <SelectItem value={property.uuid} key={index}>{property.name}</SelectItem>
                                        ))

                                    }
                                </SelectContent>
                            </Select>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="max_price"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Preço máximo</FormLabel>
                            <FormControl>
                                <NumericFormat
                                    disabled={loading}
                                    value={field.value}
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    customInput={Input}
                                    placeholder={"Digite o preço máximo"}
                                    onValueChange={(values) => {
                                        field.onChange(values.value);
                                    }}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="status"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Status</FormLabel>
                            <Select
                                disabled={loading}
                                onValueChange={(value) => {
                                    form.setValue("status", value);
                                    if (!value) {
                                        form.setValue("status", "")
                                    }
                                }}
                                value={form.watch("status") === "" ? "" : field.value}
                            >
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Todos" />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectItem value="busy">Ocupados</SelectItem>
                                    <SelectItem value="available">Disponíveis</SelectItem>
                                </SelectContent>
                            </Select>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="py-3 w-full flex flex-col-reverse">
                    <Button disabled={loading} onClick={handleReset} type="button" variant={"link"}>
                        Remover todos filtros
                    </Button>
                    <Button disabled={loading}>
                        {loading ? <Loader2 className="animate-spin" /> : "Aplicar filtros"}
                    </Button>
                </div>
            </form>
        </Form>
    );
}