import bed from "@/assets/images/cama.webp"
import people from "@/assets/images/pessoas.webp"
import bag_of_euro from "@/assets/images/saco-de-euro.webp"
import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet";

export default function AboutUsPage() {

    const navigate = useNavigate()

    const navigateToRoom = () => {
        navigate("/em-lisboa/quartos-para-alugar-em-lisboa")
    }

    return (
        <>
            <Helmet>
                <title>Alugar Um Quarto | Quem Somos</title>
                <meta name="description" content="Saiba mais agora sobre a Alugar Um Quarto e o que nos move a alugar quartos em Lisboa pelos melhores preços." />
                <meta name="keywords" content="alugar um quarto, quem é a alugar um quarto, empresa alugar um quarto, detalhes sobre a alugar um quarto, missão da alugar um quarto" />
                
                <meta property="og:title" content="Alugar Um Quarto | Quem Somos" />
                <meta property="og:description" content="Saiba mais agora sobre a Alugar Um Quarto e o que nos move a alugar quartos em Lisboa pelos melhores preços." />
                <meta property="og:url" content="https://www.alugarumquarto.pt/quem-somos" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />

                <meta name="robots" content="index, follow" />

                <link rel="canonical" href="https://www.alugarumquarto.pt/quem-somos" />
                <link rel="preload" href="https://api.alugarumquarto.pt/storage/website/about-us/dark-blue-background.webp" as="image" />
            </Helmet>

            <main className="flex flex-col items-center w-full">
                <div className="bg-[url('https://api.alugarumquarto.pt/storage/website/about-us/dark-blue-background.webp')] bg-no-repeat w-full p-10 py-14 lg:py-20 bg-cover">
                    <h1 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-semibold">
                        Bem-vindo à Alugar Um Quarto!
                    </h1>
                </div>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Quem somos?
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-1">
                        <p>
                            Nossa empresa é especializada no arrendamento de quartos individuais de alta qualidade na Grande Lisboa, com opções em localidades como Reboleira, Pontinha, Famões e Corroios.
                        </p>
                        <p>
                            Desde 2015, oferecemos acomodações confortáveis e seguras, ideais para estudantes e profissionais expatriados que estão iniciando sua nova jornada em Portugal. Todos os nossos contratos são 100% legais, com emissão de recibos que proporcionam benefícios fiscais, como desconto no IRS.
                        </p>
                        <p>
                            Atendemos exclusivamente a uma pessoa por quarto, garantindo tranquilidade e privacidade. Nossos quartos são projetados para acomodar apenas uma pessoa, com cama de solteiro, e não aceitamos crianças, animais de estimação ou a presença de mais de um ocupante por quarto.
                        </p>
                        <p>
                            Estamos focados principalmente em expatriados oriundos da CPLP, como brasileiros, angolanos, moçambicanos e são-tomenses, além de trabalharmos com outras nacionalidades, incluindo ucranianos, russos, canadenses e chilenos. Acreditamos que essa diversidade enriquece a experiência de todos os nossos inquilinos, promovendo um ambiente acolhedor e multicultural.
                        </p>
                        <p>
                            Se você busca um lugar seguro, confortável e administrado de forma profissional para morar em Portugal, nós temos a solução perfeita para você.
                        </p>
                    </div>
                </section>
                <div className="px-4 py-10 bg-[#D9D9D9] flex flex-col items-center bg-opacity-30 gap-4 w-full">
                    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center gap-6">
                        <div className="flex gap-2 self-start">
                            <img src={bed} alt="Cama" />
                            <div>
                                <p className="font-bold text-lg">39 quartos</p>
                                <p className="font-extralight text-sm">em Portugal, em breve em novos lugares.</p>
                            </div>
                        </div>
                        <div className="flex gap-2 self-start">
                            <img src={bag_of_euro} alt="Saco de euro" />
                            <div>
                                <p className="font-bold text-lg">€260</p>
                                <p className="font-extralight text-sm">preço médio dos quartos.</p>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex gap-2 self-start">
                                <img src={people} alt="Pessoas" />
                                <div>
                                    <p className="font-bold text-lg">188 inquilinos</p>
                                    <p className="font-extralight text-sm">desde o início de 2016.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-10 flex justify-center items-center">
                    <Button
                        onClick={navigateToRoom}
                        className="text-lg py-6 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] rounded-xl hover:filter hover:brightness-90 transition duration-300">
                        Conhecer todos os quartos!
                    </Button>
                </div>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Nossa missão
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-1">
                        <p>
                            Na Alugar Um Quarto, nossa missão é oferecer quartos individuais, confortáveis, seguros e totalmente mobiliados, com foco em estudantes e trabalhadores expatriados, especialmente dos países da CPLP.
                        </p>
                        <p>
                            Priorizamos localizações próximas ao metrô e outros transportes públicos, facilitando a mobilidade dos nossos inquilinos, sempre com os melhores preços do mercado. Como os portugueses têm mais facilidade no acesso à moradia, nosso foco está nos expatriados que buscam começar uma nova vida em Portugal.
                        </p>
                        <p>
                            O mais importante para nós é que nossos inquilinos vivam em paz e segurança, respeitando as regras da casa e incentivando os demais a fazerem o mesmo, promovendo assim uma convivência harmoniosa. Queremos que você se sinta em casa, parte de uma comunidade acolhedora, onde possa compartilhar experiências e construir laços duradouros.
                        </p>
                    </div>
                </section>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Conheça o que nos motiva
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-1">
                        <p>
                            A história por trás da Alugar Um Quarto começa com a experiência pessoal do nosso fundador, um brasileiro que, ao chegar a Portugal, enfrentou muitas dificuldades para alugar um quarto. O processo foi repleto de burocracia, falta de opções acessíveis e barreiras que complicaram sua busca por um lugar para morar. Sabendo o quanto essa jornada pode ser desafiadora para quem vem de fora, ele decidiu criar uma solução que facilitasse a vida de outros imigrantes.
                        </p>
                        <p>
                            Foi a partir dessa experiência que nasceu a vontade de ajudar estudantes e trabalhadores expatriados, oferecendo uma alternativa acessível, simples e segura para encontrar um espaço confortável para viver em Portugal. Nossa missão é eliminar essas barreiras, garantindo que você encontre o seu lar de forma rápida, com menos complicações e um atendimento que entende as necessidades de quem está recomeçando em um novo país.
                        </p>
                    </div>
                </section>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Já ouviu falar sobre “Moradia Compartilhada” ?
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-1">
                        <p>
                            Nossa solução para a falta de moradia para imigrantes em Portugal é a moradia compartilhada. Acreditamos que compartilhar espaços é uma forma acessível, prática e segura de encontrar um lar ao chegar em um novo país. Embora as áreas comuns sejam compartilhadas, todos os nossos quartos são individuais, garantindo privacidade e conforto para cada inquilino.
                        </p>
                    </div>
                    <div className="px-4 mt-5 flex justify-center items-center">
                        <Button
                            onClick={navigateToRoom}
                            className="text-lg py-6 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] rounded-xl hover:filter hover:brightness-90 transition duration-300">
                            Conhecer todos os quartos!
                        </Button>
                    </div>
                </section>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Nossos valores
                    </p>
                    <ul className="mt-5 space-y-5">
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Qualidade e Conforto
                            </p>
                            <p className="font-extralight">
                                Buscamos sempre oferecer ótima qualidade e conforto em nossas casas compartilhadas. Nossos quartos e casas são equipados com todos os eletrodomésticos, em um ambiente limpo e seguro, para garantir que você tenha uma experiência de moradia excepcional.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Estabilidade e Confiança
                            </p>
                            <p className="font-extralight">
                                Valorizamos a estabilidade e a confiança, oferecendo arrendamento mínimo de 6 meses para garantir um ambiente seguro e duradouro. Com isso, queremos que você tenha tranquilidade e conforto, sabendo que estará apoiado durante sua estadia conosco.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Diversidade e Respeito
                            </p>
                            <p className="font-extralight">
                                Acreditamos na importância da diversidade e do respeito mútuo. Buscamos criar um ambiente inclusivo e acolhedor, onde pessoas de diferentes origens, culturas e estilos de vida possam se sentir bem-vindas. Promovemos o diálogo aberto e o respeito pelas diferenças, valorizando a convivência harmoniosa e a troca de experiências entre os nossos moradores.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Transparência e Atendimento personalizado
                            </p>
                            <p className="font-extralight">
                                A transparência e o atendimento personalizado são essenciais para nós. Nosso objetivo é fornecer todas as informações necessárias de forma clara e acessível, para que você possa tomar decisões informadas. Além disso, nossa equipe está disponível para atendê-lo(a) de forma personalizada, respondendo às suas perguntas e auxiliando-o(a) em todas as etapas do processo de aluguel.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Comunidade e Conexões Sociais
                            </p>
                            <p className="font-extralight">
                                Valorizamos a importância da comunidade e das conexões sociais. Acreditamos que a convivência entre os moradores é enriquecedora e promove um ambiente mais harmonioso. Incentivamos a interação entre os moradores, organizando eventos e atividades que proporcionem momentos de convívio e compartilhamento de experiências. Queremos que você se sinta parte de uma comunidade acolhedora e solidária.
                            </p>
                        </li>
                    </ul>
                    <div className="px-4 mt-5 flex justify-center items-center">
                        <Button
                            onClick={navigateToRoom}
                            className="text-lg py-6 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] rounded-xl hover:filter hover:brightness-90 transition duration-300">
                            Conhecer todos os quartos!
                        </Button>
                    </div>
                </section>
                <div className="bg-[url('https://api.alugarumquarto.pt/storage/website/about-us/bedroom.webp')] bg-no-repeat bg-cover w-full p-10 lg:py-20 flex flex-col items-center">
                    <h1 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-semibold">
                        AQUI VOCÊ ENCONTRA O SEU QUARTO IDEAL!
                    </h1>
                    <Button
                        onClick={navigateToRoom}
                        className="text-black text-lg mt-3 py-6 rounded-xl bg-[#F3F5F4] hover:bg-[#F3F5F4]/70 shadow-inner shadow-black transition duration-300">
                        Conhecer todos os quartos!
                    </Button>
                </div>
            </main>
        </>
    )
}